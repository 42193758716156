import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import unified from 'unified';
import parse from 'remark-parse';
import remark2react from 'remark-react';
import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { BlockText } from 'components/block-text/BlockText';

import s from './index.scss';

// tslint:disable no-default-export
export default (params) => (
  <>
    <Helmet title="Home" />
    <Intro>
      <h1>{params.data.page.frontmatter.title}</h1>
    </Intro>
    <Intro>
      {
        unified()
          .use(parse)
          .use(remark2react, {
            remarkReactComponents: {
              strong: Highlight,
            },
          })
          .processSync(params.data.page.frontmatter.intro).result
      }
    </Intro>

    {params.data.page.frontmatter.blocks.map((block, i) => (
      <BlockText
        heading={block.title}
        key={block.title}
        className={`${s.homepageBlock} ${
          i === params.data.page.frontmatter.blocks.length - 1 ? s['last'] : 's.notLast'
        }`}
        description={
          <>
            {
              unified()
                .use(parse)
                .use(remark2react, {
                  remarkReactComponents: {
                    strong: Highlight,
                  },
                })
                .processSync(block.text).result
            }
          </>
        }
      />
    ))}
  </>
);

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query LandingPage {
    page: markdownRemark(fields: { slug: { eq: "/" } }) {
      frontmatter {
        title
        intro
        blocks {
          title
          text
        }
        subblock {
          title
          text
        }
      }
    }
  }
`;
